import chinaImg from '../../../assets/img/china.svg';
export const USERINFO = "userInfo/userInfo";
export const LANGUAGE = "app/language";
export const MENUS = "app/get/menus";
export const PERMISSION = "app/get/permission";
export const PERMISSION_SUCCESS = "app/get/permission/success";
export const RESET_STATE = "app/reset/state";
export const CLEAR = "clear/clear";
export const SET_MERCHANT_LIST = "product/setMerchantList";
export const MERCHANT = "merchant/merchant";
export const SHOP = "shop/shop";
export const EXPRESSER = "expresser/expresser";
export const SCHOOL = "school/school";
export const DOMAIN = "app/domain";
export const defaultState = {
    language: localStorage.getItem('language') || 'zh-CN',
    languages: [
      {
        key: 'zh-CN',
        title: '中文',
        flag: chinaImg,
      },
    ],
    menuList: [],
    existRoute: {}, // 用户路由列表
    isNeedPermission: false, /** 是否需要菜单-路由权限控制，默认需要true; 若设置为false，所有的permKey可以去掉 */
    userInfo: {
        // token: '8479d57cad0a449d9f9ce2b09bf1c79e',
        // customerId: '3719c2d92b23b4b0680cd1e32fbe9a4e7',
        clientType: 4,
        customerType: 3
    },
    clientType: 4,
    merchant:{},
    shop:{},
    expresser:{},
    school:{},
    domain: '',
};
