import { combineReducers } from 'redux';
import { reducer as loginReducer } from '../pages/login/store';
import { reducer as posReducer } from '../pos/cashier/store';

const reducer = combineReducers({
    login : loginReducer,
    pos : posReducer,
});

export default reducer;
