import * as constants from './constants';
export default (state = constants.defaultState, { type, payload }) => {
	let newState = JSON.parse(JSON.stringify(state));
	switch (type) {
		case constants.UPDATE_SHOPPING_CART: {
			newState.shoppingCart = payload;
			break;
		}
		case constants.UPDATE_CUT: {
			newState.cut = payload;
			break;
		}
		case constants.UPDATE_MEMBER: {
			newState.member = payload;
			break;
		}
		case constants.UPDATE_SHOPPING_CART: {
			newState.shoppingCart = payload;
			break;
		}
		case constants.RESET_SHOPPING_CART: {
			newState=constants.defaultState;
			break;
		}
		case constants.UPDATE_REMARK: {
			newState.remark = payload;
			break;
		}
		case constants.UPDATE_POST_FEE: {
			newState.postFee = payload;
			break;
		}
		case constants.UPDATE_POST_INFO: {
			newState.postInfo = payload;
			break;
		}
		default:
			return newState;
	}
	let totalNum=0,totalPrice=0;
	for(let orderProduct of newState.shoppingCart){
		totalNum+=parseInt(orderProduct.num);
		totalPrice+=(orderProduct.price*orderProduct.discount/100.0*orderProduct.num);
	}
	newState.totalNum=totalNum;
	newState.totalPrice=totalPrice;
	console.log(type+":new state shopcart:"+newState.shoppingCart.length);
	return newState;
}