import axios from 'axios';
import { showMessageError } from '../utils/handleError';

// requestUrl: 'https://work.goh8.com',
// var requestUrl = 'http://192.168.0.107:8083';
// var requestUrl = 'http://127.0.0.1:8083';
// var requestUrl = 'http://jianzhi.goh8.com:8080';
// var requestUrl = 'http://test.level-job.com';
// var requestUrl = 'http://www.huitao560.top';
// var requestUrl = 'https://mall.level-job.com';
var requestUrl = 'https://shop.level-job.com';

export default class Api {

  static getInstance() {

    if (!Api.instance) {

      Api.instance = new Api();
    }
    return Api.instance;
  }
  // 目录
  menuList(params) {
    return this.callback('/admin/menu/menuList.action', params);
  }
  menuInfo(params) {
    return this.callback('/admin/menu/publishMenu.action', params);
  }
  doPublishMenu(params) {
    return this.callback('/admin/menu/doPublishMenu.action', params);
  }
  doModifyMenu(params) {
    return this.callback('/admin/menu/doModifyMenu.action', params);
  }
  doDeleteMenuList(params) {
    return this.callback('/admin/menu/doDeleteMenuList.action', params);
  }
  // 登录
  doGetVeryCode(params) {
    return this.callback('/admin/login/doGetVeryCode.action', params);
  }
  doAccountLogin(params) {
    return this.callback('/admin/login/accountLogin.action', params);
  }
  doTokenLogin(params) {
    return this.callback('/admin/login/tokenLogin.action', params);
  }
  // 订单模块
  doOrderList(params) {
    return this.callback('/admin/order/queryOrder.action', params);
  }
  doOrderInfo(params) {
    return this.callback('/admin/order/doOrderInfo.action', params);
  }
  // 查询物流信息
  doExpressInfo(params) {
    return this.callback('/admin/order/doExpressInfo.action', params);
  }
  doOrderSuccess(params) {
    return this.callback('/admin/order/orderSuccess.action', params);
  }
  queryPostCompany(params) {
    return this.callback('/admin/postCompany/queryPostCompany.action', params);
  }
  doOrderExpress(params) {
    return this.callback('/admin/order/orderExpress.action', params);
  }
  doModifyExpress(params) {
    return this.callback('/admin/order/doModifyExpress.action', params);
  }
  doCancelOrder(params) {
    return this.callback('/admin/order/cancelOrder.action', params);
  }
  doRefundOrder(params) {
    return this.callback('/admin/order/refundOrder.action', params);
  }
  refundOrderAll(params) {
    return this.callback('/admin/order/refundOrderAll.action', params);
  }
  acceptRefundOrder(params) {
    return this.callback('/admin/order/acceptRefundOrder.action', params);
  }
  refuseRefundOrder(params) {
    return this.callback('/admin/order/refuseRefundOrder.action', params);
  }
  doModifyPayAmount(params) {
    return this.callback('/admin/order/doModifyPayAmount.action', params);
  }
  doBathExport(params) {
    return this.callback('/admin/order/doBathExport.action', params);
  }
  doBathOrderExpress(params) {
    return this.callback('/admin/order/doBathOrderExpress.action', params);
  }
  doAllExport(params) {
    return this.callback('/admin/order/doAllExport.action', params);
  }
  // 提现模块
  doTakeCashList(params) {
    return this.callback('/admin/takeCash/queryTakeCash.action', params);
  }
  doTakeCashInfo(params) {
    return this.callback('/admin/takeCash/doTakeCashInfo.action', params);
  }
  doPassTakeCash(params) {
    return this.callback('/admin/takeCash/passTakeCash.action', params);
  }
  doRefuseTakeCash(params) {
    return this.callback('/admin/takeCash/refuseTakeCash.action', params);
  }
  // 商品模块
  doProductList(params) {
    return this.callback('/admin/product/queryProduct.action', params);
  }
  doProductInfo(params) {
    return this.callback('/admin/product/doProductInfo.action', params);
  }
  doSkuInfo(params) {
    return this.callback('/admin/product/doSkuInfo.action', params);
  }
  merchantSelector(params) {
    return this.callback('/admin/merchant/merchantSelector.action', params);
  }
  doPublishProduct(params) {
    return this.callback('/admin/product/doPublishProduct.action', params);
  }
  doModifyProduct(params) {
    return this.callback('/admin/product/doModifyProduct.action', params);
  }
  doPublishSku(params) {
    return this.callback('/admin/product/doPublishSku.action', params);
  }
  doModifySku(params) {
    return this.callback('/admin/product/doModifySku.action', params);
  }
  doDeleteProduct(params) {
    return this.callback('/admin/product/doDeleteProduct.action', params);
  }
  doDeleteProductList(params) {
    return this.callback('/admin/product/doDeleteProductList.action', params);
  }
  doDeleteSku(params) {
    return this.callback('/admin/product/doDeleteSku.action', params);
  }
  doDisplayProduct(params) {
    return this.callback('/admin/product/doDisplayProduct.action', params);
  }
  doCloseProduct(params) {
    return this.callback('/admin/product/doCloseProduct.action', params);
  }
  doModifyProductData(params) {
    return this.callback('/admin/product/doModifyProductData.action', params);
  }
  //用户管理
  doCustomerList(params) {
    return this.callback('/admin/customer/doCustomerList.action', params);
  }
  modifyCustomer(params) {
    return this.callback('/admin/customer/modifyCustomer.action', params);
  }
  doModifyCustomer(params) {
    return this.callback('/admin/customer/doModifyCustomer.action', params);
  }
  charge(params) {
    return this.callback('/admin/customer/charge.action', params);
  }
  doCharge(params) {
    return this.callback('/admin/customer/doCharge.action', params);
  }
  userOpenidList(params) {
    return this.callback('/admin/userOpenid/doUserOpenidList.action', params);
  }
  basicUserList(params) {
    return this.callback('/admin/basicUser/doBasicUserList.action', params);
  }
  //商家管理
  doPublishMerchant(params) {
    return this.callback('/admin/merchant/doPublishMerchant.action', params);
  }
  queryMerchant(params) {
    return this.callback('/admin/merchant/queryMerchant.action', params);
  }
  doModifyMerchant(params) {
    return this.callback('/admin/merchant/doModifyMerchant.action', params);
  }
  doDeleteMerchant(params) {
    return this.callback('/admin/merchant/doDeleteMerchant.action', params);
  }
  doAccountDetailList(params) {
    return this.callback('/admin/accountDetail/doAccountDetailList.action', params);
  }
  //门店管理
  queryShop(params) {
    return this.callback('/admin/shop/queryShop.action', params);
  }
  doPublishShop(params) {
    return this.callback('/admin/shop/doPublishShop.action', params);
  }
  doModifyShop(params) {
    return this.callback('/admin/shop/doModifyShop.action', params);
  }
  doDeleteShop(params) {
    return this.callback('/admin/shop/doDeleteShop.action', params);
  }
  shopSelector(params) {
    return this.callback('/admin/shop/shopSelector.action', params);
  }
  doTradeList(params) {
    return this.callback('/admin/trade/doTradeList.action', params);
  }
  doLogOut(params) {
    return this.callback('/login/doLogOut.action', params);
  }
  queryDictionary(params) {
    return this.callback('/api/dictionary/queryDictionary.action', params);
  }
  // 大淘客模块-淘礼金
  doTljItemList(params) {
    return this.callback('/admin/tljItem/doTljItemList.action', params);
  }
  itemDetail(params) {
    return this.callback('/admin/tljItem/itemDetail.action', params);
  }
  modifyTljItem(params) {
    return this.callback('/admin/tljItem/modifyTljItem.action', params);
  }
  doPublishTljItem(params) {
    return this.callback('/admin/tljItem/doPublishTljItem.action', params);
  }
  doModifyTljItem(params) {
    return this.callback('/admin/tljItem/doModifyTljItem.action', params);
  }
  doDeleteTljItemList(params) {
    return this.callback('/admin/tljItem/doDeleteTljItemList.action', params);
  }
  /** 上架 */
  doTljItemOn(params) {
    return this.callback('/admin/tljItem/doTljItemOn.action', params);
  }
  /** 下架 */
  doTljItemOff(params) {
    return this.callback('/admin/tljItem/doTljItemOff.action', params);
  }
  // 模块
  moduleList(params) {
    return this.callback('/admin/module/moduleList.action', params);
  }
  moduleInfo(params) {
    return this.callback('/admin/module/publishModule.action', params);
  }
  doPublishModule(params) {
    return this.callback('/admin/module/doPublishModule.action', params);
  }
  doModifyModule(params) {
    return this.callback('/admin/module/doModifyModule.action', params);
  }
  doDeleteModuleList(params) {
    return this.callback('/admin/module/doDeleteModuleList.action', params);
  }
  doDisplayModule(params) {
    return this.callback('/admin/module/doDisplayModule.action', params);
  }
  doCloseModule(params) {
    return this.callback('/admin/module/doCloseModule.action', params);
  }
  // 模块详情
  moduleDetailList(params) {
    return this.callback('/admin/moduleDetail/moduleDetailList.action', params);
  }
  moduleDetailInfo(params) {
    return this.callback('/admin/moduleDetail/publishModuleDetail.action', params);
  }
  doPublishModuleDetail(params) {
    return this.callback('/admin/moduleDetail/doPublishModuleDetail.action', params);
  }
  doModifyModuleDetail(params) {
    return this.callback('/admin/moduleDetail/doModifyModuleDetail.action', params);
  }
  doDeleteModuleDetailList(params) {
    return this.callback('/admin/moduleDetail/doDeleteModuleDetailList.action', params);
  }
  doModifyLevel(params) {
    return this.callback('/admin/moduleDetail/doModifyLevel.action', params);
  }
  /* 淘客订单 */
  doTbkOrderList(params) {
    return this.callback('/admin/tbkOrder/doTbkOrderList.action', params);
  }
  modifyTbkOrder(params) {
    return this.callback('/admin/tbkOrder/modifyTbkOrder.action', params);
  }
  doModifyTbkOrder(params) {
    return this.callback('/admin/tbkOrder/doModifyTbkOrder.action', params);
  }
  /* 淘客订单-已完成按钮 */
  doSuccessTbkOrder(params) {
    return this.callback('/admin/tbkOrder/doSuccessTbkOrder.action', params);
  }
  /* 积分列表 */
  doCreditLogList(params) {
    return this.callback('/admin/creditLog/creditLogList.action', params);
  }
  /* 签到列表 */
  doQiandaoList(params) {
    return this.callback('/admin/qiandao/qiandaoList.action', params);
  }
  // 大淘客模块-返利商品
  doFanliItemList(params) {
    return this.callback('/admin/fanliItem/doFanliItemList.action', params);
  }
  doPublishFanliItem(params) {
    return this.callback('/admin/fanliItem/doPublishFanliItem.action', params);
  }
  modifyFanliItem(params) {
    return this.callback('/admin/fanliItem/modifyFanliItem.action', params);
  }
  doModifyFanliItem(params) {
    return this.callback('/admin/fanliItem/doModifyFanliItem.action', params);
  }
  doDeleteFanliItemList(params) {
    return this.callback('/admin/fanliItem/doDeleteFanliItemList.action', params);
  }
  /** 上架 */
  doFanliItemOn(params) {
    return this.callback('/admin/fanliItem/doFanliItemOn.action', params);
  }
  /** 下架 */
  doFanliItemOff(params) {
    return this.callback('/admin/fanliItem/doFanliItemOff.action', params);
  }
  doItemInfo(params) {
    return this.callback('/admin/fanliItem/doItemInfo.action', params);
  }
  doFeedbackList(params) {
    return this.callback('/admin/feedback/doFeedbackList.action', params);
  }
  doSiteList(params) {
    return this.callback('/admin/site/doSiteList.action', params);
  }
  modifySite(params) {
    return this.callback('/admin/site/modifySite.action', params);
  }
  doPublishSite(params) {
    return this.callback('/admin/site/doPublishSite.action', params);
  }
  doModifySite(params) {
    return this.callback('/admin/site/doModifySite.action', params);
  }
  doDeleteSiteList(params) {
    return this.callback('/admin/site/doDeleteSiteList.action', params);
  }
  doSiteDetailList(params) {
    return this.callback('/admin/siteDetail/doSiteDetailList.action', params);
  }
  doUserSiteDetailList(params) {
    return this.callback('/api/page/doSiteDetailList.action', params);
  }
  modifySiteDetail(params) {
    return this.callback('/admin/siteDetail/modifySiteDetail.action', params);
  }
  doPublishSiteDetail(params) {
    return this.callback('/admin/siteDetail/doPublishSiteDetail.action', params);
  }
  doModifySiteDetail(params) {
    return this.callback('/admin/siteDetail/doModifySiteDetail.action', params);
  }
  doDeleteSiteDetailList(params) {
    return this.callback('/admin/siteDetail/doDeleteSiteDetailList.action', params);
  }
  doModifySiteDetailLevel(params) {
    return this.callback('/admin/siteDetail/doModifySiteDetailLevel.action', params);
  }
  /** 模板消息 */
  moduleMessageList(params) {
    return this.callback('/admin/moduleMessage/moduleMessageList.action', params);
  }
  /** 短信消息 */
  messageList(params) {
    return this.callback('/admin/message/messageList.action', params);
  }
  /** 图文资讯 */
  ceefaxList(params) {
    return this.callback('/admin/ceefax/ceefaxList.action', params);
  }
  ceefaxInfo(params) {
    return this.callback('/admin/ceefax/publishCeefax.action', params);
  }
  doPublishCeefax(params) {
    return this.callback('/admin/ceefax/doPublishCeefax.action', params);
  }
  doModifyCeefax(params) {
    return this.callback('/admin/ceefax/doModifyCeefax.action', params);
  }
  doDeleteCeefaxList(params) {
    return this.callback('/admin/ceefax/doDeleteCeefaxList.action', params);
  }
  doSendCeefaxToAll(params) {
    return this.callback('/admin/ceefax/doSendCeefaxToAll.action', params);
  }
  doSendCeefaxToUser(params) {
    return this.callback('/admin/ceefax/doSendCeefaxToUser.action', params);
  }
  /** 平台参数设置 */
  doSettingInfo(params) {
    return this.callback('/admin/setting/doSettingInfo.action', params);
  }
  doModifySetting(params) {
    return this.callback('/admin/setting/doModifySetting.action', params);
  }
  /** 运营商管理 */
  customerLevelList(params) {
    return this.callback('/admin/customerLevel/customerLevelList.action', params);
  }
  customerLevelInfo(params) {
    return this.callback('/admin/customerLevel/publishCustomerLevel.action', params);
  }
  doModifyCustomerLevel(params) {
    return this.callback('/admin/customerLevel/doModifyCustomerLevel.action', params);
  }
  /** 查询返利  */
  queryFanliList(params) {
    return this.callback('/admin/queryFanli/doQueryFanliList.action', params);
  }
  doRandomQrList(params) {
    return this.callback('/admin/randomQrGroup/doRandomQrList.action', params);
  }
  doPublishRandomQr(params) {
    return this.callback('/admin/randomQrGroup/doPublishRandomQr.action', params);
  }
  doModifyRandomQr(params) {
    return this.callback('/admin/randomQrGroup/doModifyRandomQr.action', params);
  }
  doDeleteRandomQr(params) {
    return this.callback('/admin/randomQrGroup/doDeleteRandomQr.action', params);
  }
  doModifyRandomQrForIds(params) {
    return this.callback('/admin/randomQrGroup/doModifyRandomQrForIds.action', params);
  }
  doWechatQrList(params) {
    return this.callback('/admin/wechatQr/doWechatQrList.action', params);
  }
  doWechatQrForImportList(params) {
    return this.callback('/admin/wechatQr/doWechatQrForImportList.action', params);
  }
  doPublishWechatQr(params) {
    return this.callback('/admin/wechatQr/doPublishWechatQr.action', params);
  }
  doModifyWechatQr(params) {
    return this.callback('/admin/wechatQr/doModifyWechatQr.action', params);
  }
  doDeleteWechatQr(params) {
    return this.callback('/admin/wechatQr/doDeleteWechatQr.action', params);
  }
  doModifyWechatQrInfo(params) {
    return this.callback('/admin/wechatQr/doModifyWechatQrInfo.action', params);
  }
  doModifyRandomQrInfo(params) {
    return this.callback('/admin/randomQrGroup/doModifyRandomQrInfo.action', params);
  }
  doQrLogList(params) {
    return this.callback('/admin/qrLog/doQrLogList.action', params);
  }
  viewLogList(params) {
    return this.callback('/admin/viewLog/doViewLogList.action', params);
  }
  doReportRandomQrList(params) {
    return this.callback('/admin/reportQr/doReportRandomQrList.action', params);
  }
  doReportWechatQrList(params) {
    return this.callback('/admin/reportQr/doReportWechatQrList.action', params);
  }
  doModifyReportWechatQr(params) {
    return this.callback('/admin/reportQr/doModifyReportWechatQr.action', params);
  }
  // 管理员
  adminList(params) {
    return this.callback('/admin/admin/adminList.action', params);
  }
  adminSelector(params) {
    return this.callback('/admin/admin/adminSelector.action', params);
  }
  adminInfo(params) {
    return this.callback('/admin/admin/publishAdmin.action', params);
  }
  doPublishAdmin(params) {
    return this.callback('/admin/admin/doPublishAdmin.action', params);
  }
  doModifyAdmin(params) {
    return this.callback('/admin/admin/doModifyAdmin.action', params);
  }
  doDeleteAdminList(params) {
    return this.callback('/admin/admin/doDeleteAdminList.action', params);
  }
  doReportPlatformList(params) {
    return this.callback('/admin/reportPlatform/doReportPlatformList.action', params);
  }
  doReportXianzhiList(params) {
    return this.callback('/admin/reportXianzhi/doReportXianzhiList.action', params);
  }
  // 用户升级模块
  customerLevelApplyList(params) {
    return this.callback('/admin/customerLevelApply/queryCustomerLevelApply.action', params);
  }
  doCustomerLevelApplyInfo(params) {
    return this.callback('/admin/customerLevelApply/doCustomerLevelApplyInfo.action', params);
  }
  doPassCustomerLevelApply(params) {
    return this.callback('/admin/customerLevelApply/passCustomerLevelApply.action', params);
  }
  doRefuseCustomerLevelApply(params) {
    return this.callback('/admin/customerLevelApply/refuseCustomerLevelApply.action', params);
  }
  /* 数据字典 */
  dictionaryListAdmin(params) {
      return this.callback('/admin/dictionary/dictionaryList.action', params);
  }
  dictionaryInfo(params) {
      return this.callback('/admin/dictionary/publishDictionary.action', params);
  }
  doPublishDictionary(params) {
      return this.callback('/admin/dictionary/doPublishDictionary.action', params);
  }
  doModifyDictionary(params) {
      return this.callback('/admin/dictionary/doModifyDictionary.action', params);
  }
  queryDictionaryInfo(params) {
      return this.callback('/admin/dictionary/queryDictionaryInfo.action', params);
  }
  doDeleteDictionaryList(params) {
      return this.callback('/admin/dictionary/doDeleteDictionaryList.action', params);
  }
  doSyncDictionary(params) {
      return this.callback('/admin/dictionary/doSyncDictionary.action', params);
  }
  /* 聊天消息 */
  chatMsgList(params) {
      return this.callback('/admin/chatMsg/chatMsgList.action', params);
  }
  // 淘宝账号设置
  tbClientList(params) {
    return this.callback('/admin/tbClient/tbClientList.action', params);
  }
  publishTbClient(params) {
    return this.callback('/admin/tbClient/publishTbClient.action', params);
  }
  doPublishTbClient(params) {
    return this.callback('/admin/tbClient/doPublishTbClient.action', params);
  }
  doModifyTbClient(params) {
    return this.callback('/admin/tbClient/doModifyTbClient.action', params);
  }
  doDeleteTbClientList(params) {
    return this.callback('/admin/tbClient/doDeleteTbClientList.action', params);
  }
  /** 上架 */
  doTbClientOn(params) {
    return this.callback('/admin/tbClient/doTbClientOn.action', params);
  }
  /** 下架 */
  doTbClientOff(params) {
    return this.callback('/admin/tbClient/doTbClientOff.action', params);
  }

  // 淘宝账号设置
  weixinClientList(params) {
    return this.callback('/admin/weixinClient/weixinClientList.action', params);
  }
  publishWeixinClient(params) {
    return this.callback('/admin/weixinClient/publishWeixinClient.action', params);
  }
  doPublishWeixinClient(params) {
    return this.callback('/admin/weixinClient/doPublishWeixinClient.action', params);
  }
  doModifyWeixinClient(params) {
    return this.callback('/admin/weixinClient/doModifyWeixinClient.action', params);
  }
  doDeleteWeixinClientList(params) {
    return this.callback('/admin/weixinClient/doDeleteWeixinClientList.action', params);
  }
  doAreaSettingList(params) {
    return this.callback('/admin/areaSetting/doAreaSettingList.action', params);
  }
  doPublishAreaSetting(params) {
    return this.callback('/admin/areaSetting/doPublishAreaSetting.action', params);
  }
  modifyAreaSetting(params) {
    return this.callback('/admin/areaSetting/modifyAreaSetting.action', params);
  }
  doModifyAreaSetting(params) {
    return this.callback('/admin/areaSetting/doModifyAreaSetting.action', params);
  }
  doDeleteAreaSetting(params) {
    return this.callback('/admin/areaSetting/doDeleteAreaSetting.action', params);
  }

  queryIsbn(params) {
    return this.callback('/admin/isbn/queryIsbn.action', params);
  }
  doIsbnInfo(params) {
    return this.callback('/admin/isbn/doIsbnInfo.action', params);
  }
  doModifyIsbn(params) {
    return this.callback('/admin/isbn/doModifyIsbn.action', params);
  }
  doDeleteIsbn(params) {
    return this.callback('/admin/isbn/doDeleteIsbn.action', params);
  }
  queryBookLog(params) {
    return this.callback('/admin/bookLog/queryBookLog.action', params);
  }
  queryCustomerAddress(params) {
    return this.callback('/admin/customerAddress/queryCustomerAddress.action', params);
  }
  importProductToModuleList(params) {
    return this.callback('/admin/product/importProductToModuleList.action', params);
  }
  getModuleList(params) {
    return this.callback('/admin/module/getModuleList.action', params);
  }
  doModifyProductStock(params) {
    return this.callback('/admin/product/doModifyProductStock.action', params);
  }
  doModifyProductSaledNum(params) {
    return this.callback('/admin/product/doModifyProductSaledNum.action', params);
  }
  modifyOrder(params) {
    return this.callback('/admin/order/modifyOrder.action', params);
  }
  doModifyOrder(params) {
    return this.callback('/admin/order/doModifyOrder.action', params);
  }
  doImportExpressNum(params) {
    return this.callback('/admin/order/doImportExpressNum.action', params);
  }
  /* 操作日志 */
  logList(params) {
      return this.callback('/admin/log/logList.action', params);
  }
  queryLogInfo(params) {
      return this.callback('/admin/log/queryLogsInfo.action', params);
  }
  doModifyBookStockFromOrder(params) {
    return this.callback('/admin/product/doModifyBookStockFromOrder.action', params);
  }
  queryModuleProduct(params) {
    return this.callback('/admin/product/queryModuleProduct.action', params);
  }
  bookPaperList(params) {
    return this.callback('/admin/bookPaper/bookPaperList.action', params);
  }
  publishBookPaper(params) {
    return this.callback('/admin/bookPaper/publishBookPaper.action', params);
  }
  doModifyBookPaper(params) {
    return this.callback('/admin/bookPaper/doModifyBookPaper.action', params);
  }
  doDeleteBookPaperList(params) {
    return this.callback('/admin/bookPaper/doDeleteBookPaperList.action', params);
  }
  doDisplayBookPaper(params) {
    return this.callback('/admin/bookPaper/doDisplayBookPaper.action', params);
  }
  doCloseBookPaper(params) {
    return this.callback('/admin/bookPaper/doCloseBookPaper.action', params);
  }
  bookPaperDetailList(params) {
    return this.callback('/admin/bookPaper/bookPaperDetailList.action', params);
  }
  doDeleteBookPaperDetail(params) {
    return this.callback('/admin/bookPaper/doDeleteBookPaperDetail.action', params);
  }
  queryIsbn(params) {
    return this.callback('/admin/isbn/queryIsbn.action', params);
  }
  doBookInfo(params) {
    return this.callback('/admin/book/doBookInfo.action', params);
  }
  doModifyBook(params) {
    return this.callback('/admin/book/doModifyBook.action', params);
  }
  doDeleteBook(params) {
    return this.callback('/admin/book/doDeleteBook.action', params);
  }
  queryDiscount(params) {
    return this.callback('/admin/discount/queryDiscount.action', params);
  }
  doDiscountInfo(params) {
    return this.callback('/admin/discount/doDiscountInfo.action', params);
  }
  doPublishDiscount(params) {
    return this.callback('/admin/discount/doPublishDiscount.action', params);
  }
  doModifyDiscount(params) {
    return this.callback('/admin/discount/doModifyDiscount.action', params);
  }
  doDeleteDiscount(params) {
    return this.callback('/admin/discount/doDeleteDiscount.action', params);
  }
  queryProductSku(params) {
    return this.callback('/admin/productSku/queryProductSku.action', params);
  }
  doPublishProductSku(params) {
    return this.callback('/admin/productSku/doPublishProductSku.action', params);
  }
  doDeleteProductSku(params) {
    return this.callback('/admin/productSku/doDeleteProductSku.action', params);
  }
  doPublishBook(params) {
    return this.callback('/admin/book/doPublishBook.action', params);
  }
  doExport(params) {
    return this.callback('/admin/moduleDetail/doExport.action', params);
  }
  doReportPlatformList(params) {
    return this.callback('/admin/report/doReportPlatformList.action', params);
  }
  doReportProductList(params) {
    return this.callback('/admin/report/doReportProductList.action', params);
  }

  doPostCompanyList(params) {
    return this.callback('/admin/postCompany/doPostCompanyList.action', params);
  }
  doPublishPostCompany(params) {
    return this.callback('/admin/postCompany/doPublishPostCompany.action', params);
  }
  modifyPostCompany(params) {
    return this.callback('/admin/postCompany/modifyPostCompany.action', params);
  }
  doModifyPostCompany(params) {
    return this.callback('/admin/postCompany/doModifyPostCompany.action', params);
  }
  doDeletePostCompany(params) {
    return this.callback('/admin/postCompany/doDeletePostCompany.action', params);
  }
  doUploadFile(params) {
    return this.callback('/api/adminUpload/doUploadFile.action', params);
  }
  doAddOrderExpressAgain(params) {
    return this.callback('/admin/order/doAddOrderExpressAgain.action', params);
  }
  doNoDelivery(params) {
    return this.callback('/admin/order/doNoDelivery.action', params);
  }
  doReDelivery(params) {
    return this.callback('/admin/order/doReDelivery.action', params);
  }
  doPackagesList(params) {
    return this.callback('/admin/packages/doPackagesList.action', params);
  }
  doModifyPackageInfo(params) {
    return this.callback('/admin/packages/doModifyPackageInfo.action', params);
  }
  doUpdatePackageStatus(params) {
    return this.callback('/admin/packages/doUpdatePackageStatus.action', params);
  }
  modifyPackageInfo(params) {
    return this.callback('/admin/packages/modifyPackageInfo.action', params);
  }
  orderMoreExpress(params) {
    return this.callback('/admin/order/orderMoreExpress.action', params);
  }
  doPublishOrder(params) {
    return this.callback('/admin/pos/doPublishOrder.action', params);
  }
  getPostFee(params) {
    return this.callback('/admin/pos/getPostFee.action', params);
  }
  tradeDetail(params) {
    return this.callback('/admin/posTrade/tradeDetail.action', params);
  }
  microPay(params) {
    return this.callback('/admin/posTrade/microPay.action', params);
  }
  cashPay(params) {
    return this.callback('/admin/posTrade/cashPay.action', params);
  }
  queryOrder(params) {
    return this.callback('/admin/posTrade/queryOrder.action', params);
  }
  

  getUrl(){
    return requestUrl;
  }

  callback(url, data) {
    return new Promise((resolve, reject) => {
      if (url.indexOf("thirdparty") != -1) {
        url = "http://192.168.1.118:8082" + url;
      } else {
        if (url.substr(0, 4) !== 'http') {
          url = requestUrl + url;
        }
      }
      axios({
        url: url,
        data: data,
        method: 'POST',
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        /* if (res.data) {
          resolve(res.data);
        } */
        // 如果出现请登录，跳转登录页面
        if (res.data && !res.data.success && res.data.error && res.data.error === "请登录") {
          showMessageError("请重新登录");
          setTimeout(() => {
            window.location= "/";
          }, 3000);
        }
        else {
          resolve(res.data);
        }
      }).catch((error) => {
        resolve({ success: false, error: "服务器连接失败" })
      })
    });
  }
}