export const RESET_SHOPPING_CART = "pos/reset_shopping_cart";
export const UPDATE_SHOPPING_CART = "pos/update_shopping_cart";
export const UPDATE_MEMBER = "pos/update_member";
export const UPDATE_CUT = "pos/update_cut";
export const UPDATE_REMARK = "pos/update_remark";
export const UPDATE_POST_FEE = "pos/update_post_fee";
export const UPDATE_POST_INFO = "pos/update_post_info";
export const defaultState = {
    member:null,
    cut:0,
    shoppingCart:[],
    totalNum:0,
    totalPrice:0,
    postFee:0,
    remark:'',
    postInfo:{
        postWay:0,
        name:'',
        mobile:'',
        province:0,
        provinceName:'',
        city:0,
        cityName:'',
        area:0,
        areaName:'',
        address:'',
    }
};
